import React, { Component } from "react";
import { Spinner } from "@klumpp/tools";
import BackendInterface from "../utils/BackendInterface";
import { Payment } from "../utils/Types";
import "./OrderPage.scss";
import credit from "./creditcard.svg";
import paypal from "./paypal.svg";
import cash from "./cash.svg";
import RouterHistory from "../utils/RouterHistory";

export interface OrderPageProps { }

export interface OrderPageState {
    loading: boolean;
    payments: Payment[];
}

export default class OrderPage extends Component<OrderPageProps, OrderPageState>{
    state = { loading: true, payments: [] }

    componentDidMount() {
        window.auth.isAuthenticated().then((state) => {
            if (state) {
                BackendInterface.getData("/user/payments/get", data => {
                    this.setState({ payments: data, loading: false });
                })
            } else {
                window.auth.loginWithRedirect();
            }
        });
    }

    render() {
        if (this.state.loading) {
            return <Spinner></Spinner>
        }

        return <div className="order-page">
            <div className="order-page-title">Meine Bestellungen</div>
            <div className="order-page-items">
                {
                    this.state.payments.length === 0 ? "Keine Bestellungen vorhanden." : this.state.payments.map((payment: Payment, index) => <div onClick={() => RouterHistory.push(`/payment/${payment.PaymentID}`)} key={index} className="order-item">
                        <div className="order-item-method">
                            {
                                payment.method === "cash" && <img alt="Barzahlung" src={cash}></img>
                            }
                            {
                                payment.method === "paypal" && <img alt="Barzahlung" src={paypal}></img>
                            }
                            {
                                payment.method === "credit" && <img alt="Barzahlung" src={credit}></img>
                            }
                        </div>
                        <div className="order-item-personal-infos">
                            <div className="order-item-payment-prename">{payment.prename}</div>
                            <div className="order-item-payment-surname">{payment.surname}</div>
                            <div className="order-item-payment-email">{payment.email}</div>
                        </div>
                        <div className="order-item-payment-infos">
                            <div className="order-item-payment-total">
                                <div className="order-item-payment-total-label">Total:</div>
                                <div className="order-item-payment-total-value">{payment.total} €</div>
                            </div>
                            <div className="order-item-payment-state">
                                {payment.state === "canceled" && "Storniert"}
                                {payment.state === "completed" && "Bezahlt"}
                                {payment.state === "paying" && "Noch nicht bezahlt"}
                            </div>
                        </div>
                    </div>)
                }
            </div>
        </div>
    }
}