import React, { Component } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./Map.scss";

export interface MapProps { }

export interface MapState {
    lng: number;
    lat: number;
    zoom: number;
}

export default class Map extends Component<MapProps, MapState>{

    mapContainer: null | HTMLDivElement = null;

    state = {
    	lng: 8.5315916,
    	lat: 49.4738904,
    	zoom: 15
    };

    componentDidMount() {

    	mapboxgl.accessToken = "pk.eyJ1IjoiZmFiaTkxOTkiLCJhIjoiY2tibTZhc2ZuMWVvZzJzbXlwYWh4cnFuYiJ9.JchU4fLCNcRRVbwoktk4uw";
    	const map = new mapboxgl.Map({
    		container: this.mapContainer === null ? "" : this.mapContainer,
    		style: "mapbox://styles/mapbox/streets-v11",
    		center: [this.state.lng, this.state.lat],
    		zoom: this.state.zoom,

    	});

    	map.on("move", () => {
    		this.setState({
    			lng: Number(map.getCenter().lng.toFixed(4)),
    			lat: Number(map.getCenter().lat.toFixed(4)),
    			zoom: Number(map.getZoom().toFixed(2))
    		});
    	});

    	// create a HTML element for each feature
    	const el = document.createElement("div");
    	el.className = "marker";

    	// make a marker for each feature and add to the map
    	new mapboxgl.Marker(el)
    		.setLngLat([this.state.lng, this.state.lat])
    		.addTo(map);

    	new mapboxgl.Marker(el)
    		.setLngLat([this.state.lng, this.state.lat])
    		.setPopup(new mapboxgl.Popup({ offset: 25 }) // add popups
    			.setHTML("<h3>Fahrradverleih</h3><p>Hier können sie ihr Rad abholen!</p>"))
    		.addTo(map);

    }

    render() {
    	return <div className="map">
    		<div className="map-wrapper" ref={el => this.mapContainer = el}></div>
    	</div>;
    }
}