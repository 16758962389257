import React, { Component } from "react";
import BackendInterface from "../utils/BackendInterface";
import RouterHistory from "../utils/RouterHistory";
// eslint-disable-next-line no-unused-vars
import { Payment } from "../utils/Types";
import creditcard from "./creditcard.svg";
import paypal from "./paypal.svg";
import cash from "./cash.svg";
import "./PaymentPage.scss";
import moment from "moment";
import { Spinner } from "@klumpp/tools";
import PaymentDialog from "../components/payment/PaymentDialog";
import ReactCardFlip from "react-card-flip";
import { ReactComponent as PaymenChip } from "./payment-chip.svg";
import Cleave from "cleave.js/react";
import "./Creditcard.scss";
import Map from "../components/map/Map";
import PayPal, { PayPalItem } from "../utils/PayPal";

export interface PaymentPageProps { }

export interface PaymentPageState {
	payment: Payment;
	loading: boolean;
	waitForPay: boolean;
	payIsLoading: boolean;
	readonly: boolean;
	inputStep: number;
	creditCard: CreditCard;
	creditButtonActive: boolean;
}

interface CreditCard {
	number: CCProperty;
	name: CCProperty;
	date: CCProperty;
	cvv: CCProperty;
	type: {
		isValid: boolean;
		active: boolean;
	};
}

interface CCProperty {
	isValid: boolean;
	active: boolean;
	value: string;
	num: number;

}

export default class PaymentPage extends Component<PaymentPageProps, PaymentPageState>{

	inputList: React.RefObject<any>[] = [];

	state = {
		creditButtonActive: false,
		creditCard: {
			cvv: { active: false, isValid: false, value: "XXX", num: 3 },
			date: { active: false, isValid: false, value: "XX/XX", num: 2 },
			name: { active: false, isValid: false, value: "Ihr Name", num: 1 },
			number: { active: true, isValid: false, value: "XXXX XXXX XXXX XXXX", num: 0 },
			type: {}
		} as CreditCard, inputStep: 0, payIsLoading: true, waitForPay: false, loading: true, payment: { items: [], state: "pending" as any } as Payment, readonly: false
	}

	componentDidMount() {
		const id = window.location.pathname.split("/")[2];
		window.auth.isAuthenticated().then((state) => {
			if (state) {
				BackendInterface.getData(`/payment/get/${id}`, data => {

					const paypal = sessionStorage.getItem("paypal");
					sessionStorage.removeItem("paypal");

					this.setState({ payment: data, loading: false, waitForPay: data.state === "paying" || paypal !== null, payIsLoading: data.state !== "completed", readonly: data.state === "completed" });
				});
			} else {
				window.auth.loginWithRedirect();
			}
		});

	}

	onPay = () => {
		this.setState({ waitForPay: true }, () => {
			const payment = this.state.payment;
			payment.state = "paying";
			payment.readonly = true;
			BackendInterface.postData(`/payment/update/${this.state.payment.PaymentID}`, payment, () => {
				BackendInterface.deleteData("/cart/deleteCart", {}, () => {

					switch (payment.method) {
						case "cash":
							payment.state = "completed";
							payment.date = Number(moment().format("x"));
							BackendInterface.postData(`/payment/update/${this.state.payment.PaymentID}`, payment, () => {
								this.setState({ payIsLoading: false });
							});
							break;
						case "credit":
							payment.state = "completed";
							payment.date = Number(moment().format("x"));
							BackendInterface.postData(`/payment/update/${this.state.payment.PaymentID}`, payment, () => {
								this.setState({ payIsLoading: false });
							});
							break;
						case "paypal":
							this.createPayPalPayment(payment);

					}

				});
			});
		});
	}

	createPayPalPayment = (payment: Payment) => {
		const paypal = new PayPal(
			process.env.REACT_APP_PAYPAL_CLIENT_ID,
			process.env.REACT_APP_PAYPAL_CLIENT_SECRET,
			process.env.REACT_APP_PAYPAL_RETURN_URL
		);

		let items: PayPalItem[] = [];
		this.state.payment.items.forEach(item => {
			items.push({
				name: `${item.name} | ${moment(item.startDate).format("DD.MM.YYYY")} - ${moment(item.endDate).format("DD.MM.YYYY")}`,
				amount: item.total,
				quantity: item.amount
			});
		});


		paypal.createOrder("AUTHORIZE", "Ihre Bestellung bei HoneyBadger.", items, (urls: any) => {
			sessionStorage.setItem("PAYPAL_LINKS", JSON.stringify(urls));
			sessionStorage.setItem("paypal", window.location.pathname);
			payment.state = "completed";
			sessionStorage.setItem("paypal-data", JSON.stringify(payment));
			window.location = urls.approve;
		});
	};

	ccPropClassName = (baseClassName: string, inputNum: number, property: CCProperty) => {
		if (inputNum === this.state.inputStep) baseClassName += " active";
		if (property.isValid === false) baseClassName += " invalid";
		return baseClassName;
	};

	validateCCNum = (ccnum: string) => {
		var ccCheckRegExp = /[^\d\s-]/;
		var isValid = !ccCheckRegExp.test(ccnum);
		var i;

		if (isValid) {
			var cardNumbersOnly = ccnum.replace(/[\s-]/g, "");
			var cardNumberLength = cardNumbersOnly.length;

			var arrCheckTypes = ["visa", "mastercard", "amex", "discover", "dinners", "jcb"];
			for (i = 0; i < arrCheckTypes.length; i++) {
				var lengthIsValid = false;
				var prefixIsValid = false;
				var prefixRegExp;

				switch (arrCheckTypes[i]) {
					case "mastercard":
						lengthIsValid = cardNumberLength === 16;
						prefixRegExp = /5[1-5][0-9]|(2(?:2[2-9][^0]|2[3-9]|[3-6]|22[1-9]|7[0-1]|72[0]))\d*/;
						break;

					case "visa":
						lengthIsValid = cardNumberLength === 16 || cardNumberLength === 13;
						prefixRegExp = /^4/;
						break;

					case "amex":
						lengthIsValid = cardNumberLength === 15;
						prefixRegExp = /^3([47])/;
						break;

					case "discover":
						lengthIsValid = cardNumberLength === 15 || cardNumberLength === 16;
						prefixRegExp = /^(6011|5)/;
						break;

					case "dinners":
						lengthIsValid = cardNumberLength === 14;
						prefixRegExp = /^(300|301|302|303|304|305|36|38)/;
						break;

					case "jcb":
						lengthIsValid = cardNumberLength === 15 || cardNumberLength === 16;
						prefixRegExp = /^(2131|1800|35)/;
						break;

					default:
						prefixRegExp = /^$/;
				}

				prefixIsValid = prefixRegExp.test(cardNumbersOnly);
				isValid = prefixIsValid && lengthIsValid;

				// Check if we found a correct one
				if (isValid) {
					break;
				}
			}
		}

		if (!isValid) {
			return false;
		}

		// Remove all dashes for the checksum checks to eliminate negative numbers
		ccnum = ccnum.replace(/[\s-]/g, "");
		// Checksum ("Mod 10")
		// Add even digits in even length strings or odd digits in odd length strings.
		var checksum = 0;
		for (i = 2 - (ccnum.length % 2); i <= ccnum.length; i += 2) {
			checksum += parseInt(ccnum.charAt(i - 1));
		}

		// Analyze odd digits in even length strings or even digits in odd length strings.
		for (i = (ccnum.length % 2) + 1; i < ccnum.length; i += 2) {
			var digit = parseInt(ccnum.charAt(i - 1)) * 2;
			if (digit < 10) {
				checksum += digit;
			} else {
				checksum += digit - 9;
			}
		}

		return checksum % 10 === 0;
	};

	ccNumChange = (num: any) => {
		const ccNum = String(num.target.value);
		const ccNumArray = ccNum.split("");
		for (let i = 0; i < 19; i++) {
			if (ccNum[i]) {
				ccNumArray[i] = ccNum[i];
			} else {
				if ((i + 1) % 5 === 0) {
					ccNumArray[i] = " ";
				} else {
					ccNumArray[i] = "X";
				}
			}
		}
		const creditCard = this.state.creditCard;
		creditCard.number.value = ccNumArray.toString().replace(/,/g, "");
		if (ccNumArray[18] !== "X") {
			creditCard.number.isValid = this.validateCCNum(creditCard.number.value);
		} else {
			creditCard.number.isValid = false;
		}
		this.setState({ creditButtonActive: creditCard.number.isValid });
		this.saveCreditCard(creditCard);
	};

	saveCreditCard = (creditCard: CreditCard) => {
		sessionStorage.setItem("credit-card", JSON.stringify(creditCard));
		this.setState({ creditCard });
	};

	changeCCName = (e: any) => {
		const name = e.target.value;
		const creditCard = this.state.creditCard;
		creditCard.name.value = name;
		if (name.length > 3) {
			creditCard.name.isValid = true;
		} else {
			creditCard.name.isValid = false;
		}

		this.setState({ creditButtonActive: creditCard.name.isValid });
		this.saveCreditCard(creditCard);
	};

	ccDateChange = (num: any) => {
		const ccNum = String(num.target.value);
		const ccNumArray = ccNum.split("");
		for (let i = 0; i < 5; i++) {
			if (ccNum[i]) {
				ccNumArray[i] = ccNum[i];
			} else {
				if ((i + 1) % 3 === 0) {
					ccNumArray[i] = "/";
				} else {
					ccNumArray[i] = "X";
				}
			}
		}
		const creditCard = this.state.creditCard;
		creditCard.date.value = ccNumArray.toString().replace(/,/g, "");
		creditCard.date.isValid = ccNumArray[4] !== "X" ? true : false;
		this.setState({ creditButtonActive: creditCard.date.isValid });
		this.saveCreditCard(creditCard);
	};

	ccCvvChange = (num: any) => {
		const ccNum = String(num.target.value);
		const ccNumArray = ccNum.split("");
		for (let i = 0; i < 3; i++) {
			if (ccNum[i]) {
				ccNumArray[i] = ccNum[i];
			} else {
				ccNumArray[i] = "X";
			}
		}
		const creditCard = this.state.creditCard;
		creditCard.cvv.value = ccNumArray.toString().replace(/,/g, "");
		creditCard.cvv.isValid = ccNumArray[2] !== "X" ? true : false;
		this.setState({ creditButtonActive: creditCard.cvv.isValid });
		this.saveCreditCard(creditCard);
	};

	render() {

		if (this.state.loading) {
			return <Spinner></Spinner>;
		}
		const cc = this.state.creditCard;

		return <div className="payment-page">
			{this.state.waitForPay && <PaymentDialog loading={this.state.payIsLoading} method={this.state.payment.method as string}></PaymentDialog>}
			<div className="payment-top">
				<button
					onClick={() => {
						RouterHistory.goBack();
					}}
					className="detail-page-top-back"
				>
					<i className="material-icons">arrow_back</i>
				</button>
				<div className="payment-title">Bezahlvorgang</div>
			</div>
			<div className="payment-content">
				<div className="payment-information">
					<div className="payment-information-title">Persönliche Informationen:</div>
					<div className="payment-input">
						<div className="payment-input-label">Vorname:</div>
						<input type="text" disabled={this.state.readonly} className="payment-input-value surname" onChange={event => {
							const payment = this.state.payment;
							payment.prename = event.target.value;
							this.setState({ payment: payment });
						}} value={this.state.payment.prename}></input>
					</div>
					<div className="payment-input">
						<div className="payment-input-label">Nachname:</div>
						<input type="text" disabled={this.state.readonly} className="payment-input-value lastname" onChange={event => {
							const payment = this.state.payment;
							payment.surname = event.target.value;
							this.setState({ payment: payment });
						}} value={this.state.payment.surname}></input>
					</div>
					<div className="payment-input">
						<div className="payment-input-label">Email:</div>
						<input type="email" disabled={this.state.readonly} className="payment-input-value email" onChange={event => {
							const payment = this.state.payment;
							payment.email = event.target.value;
							this.setState({ payment: payment });
						}} value={this.state.payment.email}></input>
					</div>
				</div>
				<div className="payment-paymethod">
					<div className="payment-paymethod-title">Zahlungsart wählen:</div>
					<div className="payment-paymethod-items">
						<div onClick={() => {
							if (!this.state.readonly) {
								const payment = this.state.payment;
								payment.method = "cash";
								this.setState({ payment: payment });
							}
						}} className={this.state.payment.method === "cash" ? "payment-method active" : "payment-method"}>
							<div className="payment-method-img">
								<img src={cash} alt="Barzahlung"></img>
							</div>
							<div className="payment-method-label">Barzahlung</div>
						</div>
						<div onClick={() => {
							if (!this.state.readonly) {
								const payment = this.state.payment;
								payment.method = "paypal";
								this.setState({ payment: payment });
							}
						}} className={this.state.payment.method === "paypal" ? "payment-method active" : "payment-method"}>
							<div className="payment-method-img">
								<img src={paypal} alt="PayPal"></img>
							</div>
							<div className="payment-method-label">Paypal</div>
						</div>
						<div onClick={() => {
							if (!this.state.readonly) {
								const payment = this.state.payment;
								payment.method = "credit";
								this.setState({ payment: payment });
							}
						}} className={this.state.payment.method === "credit" ? "payment-method active" : "payment-method"}>
							<div className="payment-method-img">
								<img src={creditcard} alt="Kreditkarte"></img>
							</div>
							<div className="payment-method-label">
								Kreditkarte
    						</div>
						</div>
					</div>
				</div>
				<div className={this.state.payment.method === "credit" ? "payment-creditcard active" : "payment-creditcard"}>
					<div className="payment-credit-title">Kreditkartendaten:</div>
					<div className="payment-credit-wrapper">
						<div className="credit-card-input">
							<ReactCardFlip
								isFlipped={this.state.inputStep === 3 ? true : false}
								flipDirection="horizontal">
								<div className="cc">
									<div>
										<div className="chip">
											<PaymenChip />
										</div>
										<div className="type">

											<i className="material-icons">payment</i>
										</div>
										<div className={this.ccPropClassName("number", 0, cc.number)}>
											{cc.number.value}
										</div>
										<div className="name-label">Kreditkarteninhaber</div>
										<div className={this.ccPropClassName("name", 1, cc.name)}>
											{cc.name.value}
										</div>
										<div className="date-label">Gültig bis</div>
										<div className={this.ccPropClassName("date", 2, cc.date)}>
											{cc.date.value}
										</div>
									</div>
								</div>
								<div className="cc">
									<div>
										<div className="magnetic-stripe" />
										<div className="sign-field" />

										<div className={this.ccPropClassName("cvv", 3, cc.cvv)}>{cc.cvv.value}</div>

										<div className="type back">
											<i className="material-icons">payment</i>
										</div>
									</div>
								</div>
							</ReactCardFlip>
						</div>

						<form
							className="cc-inputs"
							style={{ marginLeft: `-${this.state.inputStep * 100}%` }}>
							<Cleave
								placeholder="Kreditkartennummer"
								className="hb-input cc-input"
								ref={this.inputList[0]}
								disabled={this.state.inputStep !== 0 || this.state.readonly}
								autoComplete="cc-number"
								onChange={this.ccNumChange}
								options={{ creditCard: true }}
							/>
							<input
								onChange={this.changeCCName}
								className="hb-input"
								type="text"
								name="cc-name"
								disabled={this.state.inputStep !== 1 || this.state.readonly}
								ref={this.inputList[1]}
								autoComplete="cc-name"
								placeholder="Inhaber der Kreditkarte"
							/>
							<Cleave
								placeholder="Gültig bis"
								className="hb-input cc-input"
								ref={this.inputList[2]}
								autoComplete="cc-exp"
								disabled={this.state.inputStep !== 2 || this.state.readonly}
								onChange={this.ccDateChange}
								options={{ date: true, datePattern: ["m", "y"] }}
							/>
							<Cleave
								placeholder="Sicherheitscode"
								className="hb-input cc-input"
								disabled={this.state.inputStep !== 3 || this.state.readonly}
								ref={this.inputList[3]}
								pattern="[0-9]*"
								autoComplete="cc-csc"
								onChange={this.ccCvvChange}
								options={{ numericOnly: true, blocks: [3] }}
							/>
						</form>
					</div>
					<button className="creditcard-submit" onClick={() => this.setState({ inputStep: this.state.inputStep + 1, creditButtonActive: false })} disabled={!this.state.creditButtonActive || this.state.readonly}>Weiter</button>
				</div>
				<div className={!this.state.readonly && (!this.state.payment.method || !this.state.payment.email || !this.state.payment.prename || !this.state.payment.surname || (this.state.payment.method === "credit" && (!this.state.creditCard.cvv.isValid || !this.state.creditCard.name.isValid || !this.state.creditCard.date.isValid || !this.state.creditCard.number.isValid))) ? "payment-warning active" : "payment-warning"}>Sie müssen alle Informationen angeben, bevor sie fortfahren können.</div>
				<div className={this.state.readonly || (this.state.payment.method && this.state.payment.email && this.state.payment.prename && this.state.payment.surname && (this.state.payment.method !== "credit" || (this.state.creditCard.cvv.isValid && this.state.creditCard.name.isValid && this.state.creditCard.date.isValid && this.state.creditCard.number.isValid))) ? "payment-conclusion active" : "payment-conclusion"}>
					<div className="payment-conclusion-title">Zusammenfassung:</div>
					<div className="payment-conclusion-items">

						<div className="payment-item-names">
							<div className="payment-item-title">Name</div>
							{
								this.state.payment.items.map((item, index) => <div key={index} className="payment-item-name">{item.name}</div>)
							}
						</div>
						<div className="payment-item-times">
							<div className="payment-item-title">Zeit</div>
							{
								this.state.payment.items.map((item, index) =>
									<div key={index} className="payment-item-time">
										<div className="payment-item-from">{moment(item.startDate).format("DD.MM.YYYY")}</div>
										<div className="payment-item-gap">-</div>
										<div className="payment-item-to">{moment(item.endDate).format("DD.MM.YYYY")}</div>
									</div>
								)
							}
						</div>
						<div className="payment-item-amounts">
							<div className="payment-item-title">Anzahl</div>
							{
								this.state.payment.items.map((item, index) =>
									<div key={index} className="payment-item-amount">{item.amount}</div>
								)
							}
						</div>
						<div className="payment-item-totals">
							<div className="payment-item-title">Total</div>
							{
								this.state.payment.items.map((item, index) =>
									<div key={index} className="payment-item-total">{item.total} €</div>
								)
							}
						</div>
					</div>
					<div className="payment-conclusion-pay">
						<div className="payment-conclusion-pay-total">
							<div className="payment-conclusion-pay-total-label">Total:</div>
							<div className="payment-conclusion-pay-total-value">{this.state.payment.total} €</div>
						</div>
						<button disabled={this.state.readonly} onClick={this.onPay} className="payment-conclusion-pay-btn">Bezahlen</button>
					</div>
				</div>
				{this.state.readonly && <Map></Map>}
			</div >
			<div className="payment-footer">

			</div>
		</div >;
	}
}