import request from "request-promise-native";

export interface PayPalItem {
    name: string;
    amount: number;
    quantity: number;
}

export default class PayPal {
    private clientId?: string;
    private clientSecret?: string;
    private returnUrl?: string;
    constructor(clientId?: string, clientSecret?: string, returnUrl?: string) {
        this.clientId = clientId;
        this.clientSecret = clientSecret;
        this.returnUrl = returnUrl;
    }
    private getAuthToken = () => {
        return new Promise<string>((resolve, reject) => {
            request
                .post({
                    uri: "https://api.sandbox.paypal.com/v1/oauth2/token",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    },
                    json: true,
                    auth: {
                        user: this.clientId,
                        pass: this.clientSecret
                    },
                    form: {
                        grant_type: "client_credentials"
                    }
                })
                .then(data => {
                    resolve(data.access_token);
                })
                .catch(reject);
        });
    };

    public capturePayment = (url: string, cb: (data: any) => void) => {
        this.getAuthToken()
            .then(token => {
                request
                    .post({
                        uri: url,
                        json: true,
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then(data => {
                        cb(data.payer.email_address);
                    });
            })
            .catch(() => { });
    };

    public createOrder = (
        intent: "AUTHORIZE" | "CAPTURE" = "AUTHORIZE",
        description: string,
        items: PayPalItem[],
        cb: (urls: any) => void
    ) => {
        //Parse items
        let totalAmount = 0;
        let orderItems = items.map(item => {
            totalAmount += item.amount * item.quantity;
            return {
                name: item.name,
                unit_amount: {
                    currency_code: "EUR",
                    value: `${item.amount}`
                },
                quantity: `${item.quantity}`
            };
        });

        let order = {
            intent: intent,
            purchase_units: [
                {
                    description: description,
                    invoice_id: "",
                    amount: {
                        currency_code: "EUR",
                        breakdown: {
                            item_total: {
                                currency_code: "EUR",
                                value: `${totalAmount}`
                            }
                        },
                        value: `${totalAmount}`
                    },
                    items: orderItems
                }
            ],
            application_context: {
                return_url: this.returnUrl
            }
        };

        this.getAuthToken()
            .then(token => {
                request
                    .post({
                        uri: "https://api.sandbox.paypal.com/v2/checkout/orders",
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        json: true,
                        body: order
                    })
                    .then(data => {
                        let links: any = {};
                        for (const link of data.links) {
                            links[link.rel] = link.href;
                        }
                        cb(links);
                    })
                    .catch(() => { });
            })
            .catch(() => { });
    };
}