import React, { Component } from "react";
// eslint-disable-next-line no-unused-vars
import { CartItem, Bike } from "../../utils/Types";
import BackendInterface from "../../utils/BackendInterface";
import { Spinner } from "@klumpp/tools";
import "./ShoppingcartItem.scss";
import moment from "moment";
import { DateRangePicker } from "react-dates";

export interface ShoppingcartItemProps {
	item: CartItem;
	onDelete: () => void;
}

export interface ShoppingcartItemState {
	bike?: Bike;
	loading: boolean;
	temp_amount: number;
	dateFocus: null | "startDate" | "endDate";
	startDate: moment.Moment | number | null;
	endDate: moment.Moment | number | null;
	days: any;
	total: number;
	valid: boolean;
}

export default class ShoppingcartItem extends Component<ShoppingcartItemProps, ShoppingcartItemState>{

	state = {
		loading: true,
		bike: undefined,
		temp_amount: this.props.item.amount,
		dateFocus: null,
		startDate: this.props.item.startDate,
		endDate: this.props.item.endDate,
		days: this.props.item.days,
		total: this.props.item.total,
		valid: true
	}

	componentDidMount() {
		BackendInterface.getData(`/bicycles/get/${this.props.item.bicycle_id}`, (data: Bike) => {
			this.setState({ bike: data, loading: false });
		});
	}

	delete = () => {
		this.setState({ loading: true }, () => {
			BackendInterface.deleteData(`/cart/deleteItem/${this.props.item.item_id}`, {}, () => {
				this.setState({ loading: false }, () => {
					this.props.onDelete();
				});
			});
		});
	}

	onChangeAmount = () => {
		if (this.state.temp_amount !== 0) {
			if (this.state.temp_amount !== this.props.item.amount) {
				BackendInterface.postData(
					`/cart/updateItem/${this.props.item.item_id}`,
					{
						amount: this.state.temp_amount,
						startDate: this.props.item.startDate,
						endDate: this.props.item.endDate,
						days: this.props.item.days,
						total: (this.state.days * this.state.temp_amount * ((this.state.bike as any) as Bike).price)
					},
					() => {
						this.props.item.amount = this.state.temp_amount;
						this.setState({ total: (this.state.days * this.state.temp_amount * ((this.state.bike as any) as Bike).price) });

					}
				);
			}
		} else {
			this.setState({ temp_amount: this.props.item.amount });
		}
	}

	checkItem = () => {

		let valid = true;
		if (this.props.item.amount === this.state.temp_amount && this.props.item.amount > 0 && this.state.startDate && this.state.endDate) {
			valid = true;
		} else {
			valid = false;
		}

		const startDate = moment(this.state.startDate);
		const endDate = moment(this.state.endDate);

		while (startDate < endDate) {

			if (this.isDayBlocked(startDate)) {
				valid = false;
			}

			startDate.add(1, "days");
		}

		this.setState({ valid: valid });
		return valid;
	}

	changeAmountValue = (event: any) => {
		const value = Number(event.target.value);
		if (!isNaN(value)) {
			this.setState({ temp_amount: value });
		}
	}

	isDayBlocked = (day: any) => {

		const capacity = (this.state.bike as any).capacity[day.format("DD.MM.YYYY")];
		if (!capacity) {
			return true;
		}

		if (capacity - this.props.item.amount >= 0) {
			return false;
		}
		return true;
	}

	onShoppingcartTimeChange = (arg: { startDate: moment.Moment | null, endDate: moment.Moment | null }) => {
		const days = arg.startDate !== null && arg.endDate !== null ? ((arg.endDate as any) as moment.Moment).diff(arg.startDate, "days") + 1 : "";
		this.setState({ ...arg, days: days }, () => {
			if (arg.startDate !== null && arg.endDate !== null) {
				BackendInterface.postData(
					`/cart/updateItem/${this.props.item.item_id}`,
					{
						amount: this.state.temp_amount,
						startDate: Number((this.state.startDate as any).format("x")),
						endDate: Number((this.state.endDate as any).format("x")),
						days: this.state.days,
						total: (this.state.temp_amount * this.state.days * ((this.state.bike as any) as Bike).price)
					},
					() => {
						this.props.item.startDate = Number((this.state.startDate as any).format("x"));
						this.props.item.endDate = Number((this.state.endDate as any).format("x"));
						this.setState({ total: (this.state.temp_amount * this.state.days * ((this.state.bike as any) as Bike).price) });
					}
				);
			}
		});

	}

	render() {
		if (this.state.loading) {
			return <Spinner></Spinner>;
		}

		const bike = ((this.state.bike) as any) as Bike;
		return <div className={this.state.valid ? "cart-item" : "cart-item invalid"}>
			<button className="cart-item-delete" onClick={this.delete}><i className="material-icons">delete</i></button>
			<div className="cart-item-img">
				<img alt="Bike" src={bike.imgs[0]}></img>
			</div>
			<div className="cart-item-details-title">{bike.name}</div>
			<div className="cart-item-details">
				<div className="cart-item-details-labels">
					<div className="cart-item-details-producer-label">Hersteller:</div>
					<div className="cart-item-details-color-label">Farbe:</div>
					<div className="cart-item-details-size-label">Reifengröße:</div>
				</div>
				<div className="cart-item-details-values">
					<div className="cart-item-details-producer-value">{bike.producer}</div>
					<div className="cart-item-details-color-value">{bike.color}</div>
					<div className="cart-item-details-size-value">{bike.wheel_size}</div>
				</div>
			</div>
			<div className="cart-item-payment">
				<div className="cart-item-payment-labels">
					<div className="cart-item-payment-amount-label">Anzahl:</div>
					<div className="cart-item-payment-days-label">Tage:</div>
					<div className="cart-item-payment-price-label">Preis pro Tag:</div>
				</div>
				<div className="cart-item-payment-values">
					<div className="cart-item-payment-amount-value">
						<input className="cart-item-amount-input" type="text" onChange={this.changeAmountValue} onBlur={this.onChangeAmount} value={this.state.temp_amount}></input>
					</div>
					<div className="cart-item-payment-days-value">{this.state.days}</div>
					<div className="cart-item-payment-price-value">{bike.price} €</div>
				</div>
			</div>
			<div className="cart-item-line"></div>
			<div className="cart-item-conclusion">
				<div className="shoppingcart-time">
					<DateRangePicker
						endDateId="endDate"
						startDateId="startDate"
						orientation="vertical"
						small
						regular
						focusedInput={this.state.dateFocus}
						startDate={this.state.startDate ? moment(this.state.startDate) : null}
						endDate={this.state.endDate ? moment(this.state.endDate) : null}
						onDatesChange={this.onShoppingcartTimeChange}
						onFocusChange={(focus) => { this.setState({ dateFocus: focus }); }}
						displayFormat={"DD.MM.YYYY"}
						isDayBlocked={this.isDayBlocked}
						startDatePlaceholderText="Startdatum"
						endDatePlaceholderText="Enddatum"
						minimumNights={0}
						renderDayContents={(day) => {
							const capacity = ((this.state.bike as any).capacity)[day.format("DD.MM.YYYY")];
							return <div className="date-value-field">
								<div className="date-value">{day.format("DD")}</div>
								{capacity !== undefined && <div className="date-capacity">
									<div className="date-capacity-value">{capacity}</div>
									<i className="material-icons">directions_bike</i>
								</div>}
							</div>;
						}}
					/>
				</div>
				<div className="cart-item-conclusion-total-label">Total:</div>
				<div className="cart-item-conclusion-total-value">{this.state.total} €</div>
			</div>
		</div>;
	}
}