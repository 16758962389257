import React, { Component } from "react";
import RouterHistory from "../../utils/RouterHistory";
import Swiper from "swiper";
import "swiper/css/swiper.css";
import "swiper/js/swiper";
import "./StartpageSlider.scss";
// eslint-disable-next-line no-unused-vars
import { Bike } from "../../utils/Types";
import Item from "../items/Item";

export interface StartpageSliderProps {
	title: string;
	toAll: string;
	bikes: Bike[];
	index: number;
}

export interface StartpageSliderState { }

export default class StartpageSlider extends Component<
	StartpageSliderProps,
	StartpageSliderState
	> {
	state = { bikes: [] };

	swiper?: any = undefined;

	componentDidMount() {
		this.swiper = new Swiper(".swiper-container", {
			slidesPerView: "auto",
			freeMode: true,
			watchOverflow: true, //disables nav buttons if unnecessary
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			observer: true,
			observeParents: true,
		});
	}

	render() {
		if (
			this.props.index === 0 &&
			window.cookies &&
			window.cookies.get("slide0")
		) {
			(document.querySelector(".slider-0") as any) &&
				((document.querySelector(
					".slider-0"
				) as any).swiper.activeIndex = window.cookies.get("slide0"));
		} else if (
			this.props.index === 1 &&
			window.cookies &&
			window.cookies.get("slide1")
		) {
			(document.querySelector(".slider-1") as any) &&
				((document.querySelector(
					".slider-1"
				) as any).swiper.activeIndex = window.cookies.get("slide1"));
		} else if (
			this.props.index === 2 &&
			window.cookies &&
			window.cookies.get("slide2")
		) {
			(document.querySelector(".slider-2") as any) &&
				((document.querySelector(
					".slider-2"
				) as any).swiper.activeIndex = window.cookies.get("slide2"));
		}
		return (
			<div className="slider">
				<div className="slider-top">
					<div className="slider-title">{this.props.title}</div>
					<div className="slider-show-all">
						<button
							onClick={() => RouterHistory.push(this.props.toAll)}
						>
							Alles anzeigen
						</button>
					</div>
				</div>
				<div
					className={`slider-content swiper-container slider-${this.props.index}`}
				>
					<div
						className="swiper-wrapper"
						style={{ margin: "0px 50px" }}
					>
						{this.props.bikes.map((item: Bike, index) => {
							return (
								<div
									key={index}
									className="slider-item swiper-slide"
								>
									<Item
										id={item.id}
										key={index}
										imgSrc={
											item.imgs.length === 0
												? ""
												: item.imgs[0]
										}
										title={item.name}
										swiper={
											this.swiper[this.props.index]
												? this.swiper[this.props.index]
												: undefined
										}
										swiperId={this.props.index}
									></Item>
								</div>
							);
						})}
					</div>
					{/* Add Arrows */}
					<div className="swiper-button-next"></div>
					<div className="swiper-button-prev"></div>
				</div>
			</div>
		);
	}
}
