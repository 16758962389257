import React from "react";
import Swiper from "swiper";
import "swiper/css/swiper.css";
import "swiper/js/swiper";
import "./DetailpageSlider.scss";
import Item from "../items/Item";

export interface Img {
	src: string;
	title: string;
}

export interface DetailpageSliderProps {
	srcs: Img[];
}

export interface DetailpageSliderState { }

export default class DetailpageSlider extends React.Component<
	DetailpageSliderProps,
	DetailpageSliderState
	> {
	componentDidMount() {
		new Swiper(".swiper-container", {
			pagination: {
				el: ".swiper-pagination",
				type: "bullets",
				clickable: true,
			},
			slidesPerView: 1,
			watchOverflow: true, //disables nav buttons if unnecessary
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			observer: true,
			observeParents: true,
		});
	}

	render() {
		return (
			<div className="slider-wrapper">
				<div className="slider-content swiper-container">
					<div className="swiper-wrapper">
						{this.props.srcs.map((item, index) => {
							return (
								<div
									key={index}
									className="slider-item swiper-slide"
								>
									<Item
										noClick
										id={""}
										imgSrc={item.src}
										title={item.title}
									></Item>
								</div>
							);
						})}
					</div>
					<div className="swiper-button-prev"></div>
					<div className="swiper-button-next"></div>
					<div className="swiper-pagination"></div>
				</div>
			</div>
		);
	}
}
