import React, { Component } from "react";
import "./Checkbox.scss";

export interface CheckboxProps{
    value: boolean;
    onChange: (value: boolean) => void;
    label: string;
}

export interface CheckboxState{}

export default class Checkbox extends Component<CheckboxProps, CheckboxState>{
	render(){
		return <div className="checkbox-input noselect">
			<i className="checkbox-input-button material-icons" onClick={() => {this.props.onChange(!this.props.value);}}>{this.props.value?"check":""}</i>
			<div className="checkbox-input-label">{this.props.label}</div>
		</div>;
	}
}