import React, { Component } from "react";
import { Spinner } from "@klumpp/tools";
import "./PaymentDialog.scss";
import RouterHistory from "../../utils/RouterHistory";
import confirm_icon from "./confirm.svg";

export interface PaymentDialogProps {
    loading: boolean;
    method: string;
}

export interface PaymentDialogState { }

export default class PaymentDialog extends Component<PaymentDialogProps, PaymentDialogState>{

    render() {
        let method = "";
        switch (this.props.method) {
            case "credit": method = "Kreditkarte"; break;
            case "paypal": method = "PayPal"; break;
            case "cash": method = "Barzahlung"; break;
        }
        return <div className="payment-dialog">
            <div className="payment-dialog-title">Bestellung erfolgreich!</div>
            <div className="payment-dialog-method">{method}</div>
            <div className="payment-dialog-text">Sie können ihre Bestellung nun in einem unserer Shops abholen. Bitte denken sie an Ihren Personalausweis.</div>
            <div className="payment-dialog-img">
                <img src={confirm_icon} alt="Confirm"></img>
            </div>
            {this.props.loading ? <Spinner></Spinner> : <div className="payment-dialog-buttons">
                <button onClick={() => RouterHistory.replace("/")}>Zurück zur Startseite.</button></div>}
        </div>
    }
}