import React, { Component } from "react";
import "./FoldableFilterItem.scss";

export interface FoldableFilterItemProps{
    title: string;
    open?: boolean;
}

export interface FoldableFilterItemState{
    up: boolean;
}

export default class FoldableFilterItem extends Component<FoldableFilterItemProps, FoldableFilterItemState>{

	constructor(props: any){
		super(props);
    	if(this.props.open){
			this.state = {up: false};
		}else{
			this.state = {up: true};
		};
	}

	open = () => {
		this.setState({up: false});
	}

    getClasses = () => {
    	let classes = ["filter-foldable-item"];

    	if(this.state.up){
    		classes.push("filter-foldable-item-up");
    	}

    	return classes.join(" ");
    }

    render(){
    	return <div className={this.getClasses()}>
    		<div onClick={() => this.setState({up: !this.state.up})} className="noselect filter-foldable-item-title">{this.props.title}</div>
    		<button className="noselect filter-foldable-item-button" onClick={() => this.setState({up: !this.state.up})} >
    			<i className="material-icons">keyboard_arrow_down</i>
    		</button>
    		<div className="filter-foldable-item-content">
    			{this.props.children}
    		</div>
    	</div>;
    }
}