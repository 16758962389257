import React, { Component } from "react";
import { Spinner, showNotification } from "@klumpp/tools";
import "./Shoppingcart.scss";
// eslint-disable-next-line no-unused-vars
import { CartItem, Payment } from "../utils/Types";
import ShoppingcartItem from "../components/items/ShoppingcartItem";
import RouterHistory from "../utils/RouterHistory";
import BackendInterface from "../utils/BackendInterface";

export interface ShoppingcartProps { }

export interface ShoppingcartState {
	loading: boolean;
	total: number;
	items: CartItem[];
}

export default class Shoppingcart extends Component<
	ShoppingcartProps,
	ShoppingcartState
	> {
	itemRefs: React.RefObject<ShoppingcartItem>[] = [];

	state = {
		loading: true, total: 0, items: []
	};

	loadItems = () => {
		let total = 0;
		this.setState({ items: [] }, () => {
			BackendInterface.getData("/cart/get", data => {
				data.forEach((item: CartItem) => total += item.total);
				this.itemRefs = data.map(() => React.createRef<ShoppingcartItem>());
				this.setState({ loading: false, total: total, items: data });
			});
		});
	}

	componentDidMount() {
		window.auth.isAuthenticated().then((state) => {
			if (state) {
				//check if local storage filled
				let cart: any = window.localStorage.getItem("shoppingCart");
				if (!cart) {
					cart = [];
				} else {
					cart = JSON.parse(cart);
				}
				let count = 0;
				if (cart.length === 0) {
					this.loadItems();
				}
				for (const item of cart) {
					// eslint-disable-next-line
					BackendInterface.postData("/cart/addItem", item, () => {
						count++;
						if (count === cart.length) {
							window.localStorage.removeItem("shoppingCart");
							this.loadItems();
						}
					});
				}
			} else {
				window.auth.loginWithRedirect();
			}
		});
	}

	checkItems = () => {
		let valid = true;
		this.itemRefs.forEach(ref => {
			if (!ref.current?.checkItem()) {
				valid = false;
			}
		});

		return valid;
	}

	createPayment = () => {
		if (this.state.items.length !== 0 && this.checkItems()) {
			this.setState({ loading: true }, () => {
				const payment: Payment = { items: this.state.items, state: "pending" };
				BackendInterface.postData("/payment/create", payment, data => {
					if (data) {
						RouterHistory.push(`/payment/${data.id}`);
					} else {
						this.setState({ loading: false }, () => {
							showNotification({ msg: "Fehlerhafte Zeiträume!", type: "E" });
						});
					}
				});
			});
		}
	}

	render() {
		if (this.state.loading) {
			return <Spinner></Spinner>;
		} else {
			return <div className="shoppingcart">
				<div className="cart-title">Warenkorb</div>
				<div className="cart-items">
					<div className="cart-items-wrapper">
						{
							this.state.items.length === 0 ? "Keine Einträge vorhanden!" : this.state.items.map((item, index) => <ShoppingcartItem ref={this.itemRefs[index]} onDelete={this.loadItems} key={index} item={item}></ShoppingcartItem>)
						}
					</div>
				</div>
				<div className="cart-footer">
					<div className="cart-footer-total">
						<div className="cart-footer-total-label">Total: </div>
						<div className="cart-footer-total-value">
							{this.state.total} €
						</div>
					</div>
					<button className="cart-footer-pay" onClick={this.createPayment}>Zur Kasse.</button>
				</div>
			</div>;
		}
	}
}
