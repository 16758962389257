import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./App.css";
import StartPage from "./pages/StartPage";
import FilterPage from "./pages/FilterPage";
import WishlistPage from "./pages/WishlistPage";
import "./App.scss";
import Navigation from "./components/navigations/Navigation";
import DetailPage from "./pages/DetailPage";
import PaymentPage from "./pages/PaymentPage";
import { withCookies } from "react-cookie";
import Auth from "./components/auth/Auth";
import RouterHistory from "./utils/RouterHistory";
import AuthCallback from "./components/auth/AuthCallback";
import Shoppingcart from "./pages/Shoppingcart";
import OrderPage from "./pages/OrderPage";
import PaypalRedirect from "./components/paypal/PayPal";
import { ToastContainer } from "@klumpp/tools";
import 'react-toastify/dist/ReactToastify.css';

class App extends Component<any, any> {
	auth: Auth;
	constructor(props: any) {
		super(props);
		this.auth = new Auth(
			RouterHistory,
			process.env.REACT_APP_AUTH0_DOMAIN as string,
			process.env.REACT_APP_AUTH0_CLIENT_ID as string,
			process.env.REACT_APP_AUTH0_REDIRECT_URL as string,
			process.env.REACT_APP_AUTH0_AUDIENCE as string,
			process.env.REACT_APP_AUTH0_REDIRECT_LOGOUT_URL as string
		);

		window.auth = this.auth;
		window.cookies = this.props.cookies;
	}

	render() {
		return (
			<div className="App">
				<ToastContainer></ToastContainer>
				<Switch>
					<Route
						exact
						path="/"
						render={() => <Redirect to="/home" />}
					/>
					<Route
						exact
						path="/details/:itemId"
						component={DetailPage}
					/>
					<Route
						exact
						path="/payment/:paymentId"
						component={PaymentPage}
					/>
					<Route
						exact
						path="/callback"
						render={() => (
							<AuthCallback auth={this.auth}></AuthCallback>
						)}
					/>
					<Route
						exact
						path="/paypal"
						render={() => (
							<PaypalRedirect></PaypalRedirect>
						)}
					/>
					<Route path="/home">
						<Navigation></Navigation>
						<Switch>
							<Route
								exact
								path="/home"
								render={() => <StartPage />}
							/>
							<Route
								exact
								path="/home/search"
								component={FilterPage}
							/>
							<Route
								exact
								path="/home/wishlist"
								component={WishlistPage}
							/>
							<Route
								exact
								path="/home/shoppingcart"
								component={Shoppingcart}
							/>
							<Route
								exact
								path="/home/orders"
								component={OrderPage}
							/>
						</Switch>
					</Route>
				</Switch>
			</div>
		);
	}
}

export default withCookies(App);
