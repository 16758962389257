import React, { Component } from "react";
import "./Select.scss";

export interface SelectProps{
    items: string[];
    onChange: (value: string) => void;
    value: string;
}

export interface SelectState{
    open: boolean;
}

export default class Select extends Component<SelectProps, SelectState>{
    state = {open: false};
    toggle = () => {
    	this.setState({open: !this.state.open});
    }

    getClasses = () => {
    	let classes = ["select-box"];

    	if(this.state.open){
    		classes.push("select-box-open");
    	}

    	return classes.join(" ");
    }

    render(){
    	return <div className={this.getClasses()} onBlur={() => {this.setState({open: false});}} onClick={this.toggle}>
    		<div className="select-box-value"  >{this.props.value}</div>
    		<div className="select-box-arrow"><i className="material-icons">keyboard_arrow_down</i></div>
    		<div className="select-box-menu">
    			{this.props.items.map((item, number) => <div key={number} onClick={() => {this.props.onChange(item);}} className="select-box-menu-item">{item}</div>)}
    		</div>
    	</div>;
    }
}