import React, { Component } from "react";
import "./Item.scss";
import RouterHistory from "../../utils/RouterHistory";
// eslint-disable-next-line no-unused-vars
import Swiper from "swiper";

export interface ItemProps {
	id: string;
	imgSrc: string;
	title: string;
	noClick?: boolean;
	swiper?: Swiper;
	swiperId?: number;
}

export interface ItemState { }

export default class Item extends Component<ItemProps, ItemState> {
	showDetails = () => {
		if (!this.props.noClick) {
			if (this.props.swiper?.isEnd) {
				window.cookies.set(`slide${this.props.swiperId}`, this.props.swiper ? this.props.swiper.activeIndex + 1 : 0, { maxAge: 3600 });
			} else {
				window.cookies.set(`slide${this.props.swiperId}`, this.props.swiper ? this.props.swiper.activeIndex : 0, { maxAge: 3600 });
			}
			RouterHistory.push(`/details/${this.props.id}`);
		}
	};

	render() {
		return (
			<div className="item" onClick={this.showDetails}>
				<div className="item-top">
					<div className="item-title">{this.props.title}</div>
				</div>
				<div className="item-pic">
					<img
						alt="Bild"
						className="noselect"
						src={this.props.imgSrc}
					></img>
				</div>
			</div>
		);
	}
}
