import React from "react";
import Swiper from "swiper";
import "swiper/css/swiper.css";
import "swiper/js/swiper";
import { Bike } from "../../utils/Types";
import Item from "../items/Item";
import "./WishlistpageSlider.scss";

export interface WishlistpageSliderProps {
	title: string;
	index: number;
	bikes: Bike[];
}

export interface WishlistpageSliderState {

}

export default class WishlistpageSlider extends React.Component<WishlistpageSliderProps, WishlistpageSliderState> {

	swiper?: any = undefined;

	componentDidMount() {
		this.swiper = new Swiper(".swiper-container", {
			slidesPerView: "auto",
			freeMode: true,
			watchOverflow: true, //disables nav buttons if unnecessary
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			observer: true,
			observeParents: true,
		});
	}

	render() {
		return (
			<div className="wishlist-slider">
				<div className="slider-top">
					<div className="slider-title">{this.props.title}</div>
				</div>
				<div
					className={`slider-content swiper-container slider-${this.props.index}`}
				>
					<div
						className="swiper-wrapper"
						style={{ margin: "0px 50px" }}
					>
						{this.props.bikes.map((item: Bike, index) => {
							return (
								<div
									key={index}
									className="slider-item swiper-slide"
								>
									<Item
										id={item.id}
										key={index}
										imgSrc={
											item.imgs.length === 0
												? ""
												: item.imgs[0]
										}
										title={item.name}
									// swiper={
									// 	this.swiper[this.props.index]
									// 		? this.swiper[this.props.index]
									// 		: undefined
									// }
									// swiperId={this.props.index}
									></Item>
								</div>
							);
						})}
					</div>
					{/* Add Arrows */}
					<div className="swiper-button-next"></div>
					<div className="swiper-button-prev"></div>
				</div>
			</div>
		);
	}
}