import React, { Component } from "react";
import "./Navigation.scss";
import RouterHistory from "../../utils/RouterHistory";
import { matchPath } from "react-router-dom";
import logo from "../../static/Logo.png";
import banner from "../../static/Banner.png";
// eslint-disable-next-line no-unused-vars
import { UnregisterCallback } from "history";

export interface NavigationProps { }

export interface NavigationState {
	small: boolean;
	pathName: string;
	authIcon: string;
	user: any;
	openUser: boolean;
	loggedIn: boolean;
}

export default class Navigation extends Component<
	NavigationProps,
	NavigationState
	> {
	state = {
		small: false,
		openUser: false,
		pathName: "",
		authIcon: "login",
		user: { picture: process.env.REACT_APP_DUMMY, nickname: "" },
		loggedIn: false,
	};
	historyListener?: UnregisterCallback;
	setSmall = (small: boolean) => {
		if (this.state.small !== small) {
			this.setState({ small: small });
		}
	};

	componentDidMount() {
		this.historyListener = RouterHistory.listen((location) => {
			this.setState({ pathName: location.pathname });
		});

		window.auth.isAuthenticated().then((state) => {
			if (state) {
				this.setState({ authIcon: "logout" });
			}
		});

		window.auth.getUser().then((user: any) => {
			if (user) {
				this.setState({ user: user, loggedIn: true });
			}
		});

		this.setState({ pathName: window.location.pathname });
	}

	componentWillUnmount() {
		this.historyListener && this.historyListener();
	}

	getClasses = () => {
		let classes = ["navigation"];

		if (this.state.small) {
			classes.push("small-nav");
		}

		return classes.join(" ");
	};

	render() {
		if (matchPath(this.state.pathName, { exact: true, path: "/home" })) {
			this.setSmall(false);
		} else {
			this.setSmall(true);
		}

		return (
			<div className={this.getClasses()}>
				<div className="navigation-wrapper">
					<div className="navigation-buttons">
						<button
							className={
								matchPath(this.state.pathName, {
									exact: true,
									path: "/home",
								})
									? "matchpath"
									: ""
							}
							onClick={() => {
								!matchPath(this.state.pathName, {
									exact: true,
									path: "/home",
								}) &&
									this.setState({ openUser: false }, () =>
										RouterHistory.push("/home")
									);
							}}
						>
							Startseite
						</button>
						<button
							className={
								matchPath(this.state.pathName, {
									exact: true,
									path: "/home/search",
								})
									? "matchpath"
									: ""
							}
							onClick={() => {
								!matchPath(this.state.pathName, {
									exact: true,
									path: "/home/search",
								}) &&
									this.setState({ openUser: false }, () =>
										RouterHistory.push("/home/search")
									);
							}}
						>
							Suche
						</button>
						<button
							className="user-button"
							onClick={() => {
								this.setState({
									openUser: !this.state.openUser,
								});
							}}
						>
							<img
								alt="Profilbild"
								src={this.state.user.picture}
							></img>
						</button>
					</div>
					<div className="navigation-banner">
						<img
							alt="Banner"
							className="noselect"
							src={banner}
						></img>
					</div>
					<div className="navigation-logo">
						<img alt="Logo" className="noselect" src={logo}></img>
					</div>
				</div>
				<div
					className={
						this.state.openUser
							? "user-button-pane open-user"
							: "user-button-pane"
					}
				>
					<div className="user-button-title">
						{this.state.loggedIn
							? `Willkommen, ${this.state.user.nickname}!`
							: "Sie sind nicht eingeloggt."}
					</div>
					<div className="user-button-content">
						<button
							className={
								matchPath(this.state.pathName, {
									exact: true,
									path: "/home/wishlist",
								})
									? "matchpath"
									: ""
							}
							onClick={() => {
								!matchPath(this.state.pathName, {
									exact: true,
									path: "/home/wishlist",
								}) &&
									this.setState({ openUser: false }, () =>
										RouterHistory.push("/home/wishlist")
									);
							}}
						>
							Wunschliste
						</button>
						<button
							className={
								matchPath(this.state.pathName, {
									exact: true,
									path: "/home/shoppingcart",
								})
									? "matchpath"
									: ""
							}
							onClick={() => {
								!matchPath(this.state.pathName, {
									exact: true,
									path: "/home/shoppingcart",
								}) &&
									this.setState({ openUser: false }, () =>
										RouterHistory.push("/home/shoppingcart")
									);
							}}
						>
							Warenkorb
						</button>
						<button
							className={
								matchPath(this.state.pathName, {
									exact: true,
									path: "/home/orders",
								})
									? "matchpath"
									: ""
							}
							onClick={() => {
								!matchPath(this.state.pathName, {
									exact: true,
									path: "/home/orders",
								}) &&
									this.setState({ openUser: false }, () =>
										RouterHistory.push("/home/orders")
									);
							}}
						>
							Meine Bestellungen
						</button>
					</div>
					<div className="user-button-footer">
						<button
							onClick={async () => {
								if (await window.auth.isAuthenticated()) {
									window.auth.logout();
								} else {
									window.auth.loginWithRedirect();
								}
							}}
						>
							<i className="material-icons">
								{this.state.authIcon}
							</i>
						</button>
					</div>
				</div>
			</div>
		);
	}
}
