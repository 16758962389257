import React, { Component } from "react";
import "./Text.scss";

export interface TextProps{
    value: string;
    onChange: (value: string) => void;
}

export interface TextState{}

export default class Text extends Component<TextProps, TextState>{
	render(){
		return <input className="text-input" value={this.props.value} type="text" name="search" placeholder="Suchen..." onChange={(value) => this.props.onChange(value.target.value)}></input>;
	}
}