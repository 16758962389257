import { toast } from "react-toastify";

type response = {
	msg: string;
	type: "E" | "S" | "W";
};

export function showNotification(
	response: response,
	position: "bottom-left" | "bottom-right" = toast.POSITION.BOTTOM_LEFT as any
) {
	try {
		switch (response.type) {
			case "E":
				toast.error(response.msg, {
					position,
				});
				break;

			case "S":
				toast.success(response.msg, {
					position,
				});
				break;

			default:
				toast.warn(response.msg, {
					position,
				});
				break;
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.log(err);
	}
}
