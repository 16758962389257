import React, { Component } from "react";
import StartpageSlider from "../components/sliders/StartpageSlider";
import "./StartPage.scss";
import BackendInterface from "../utils/BackendInterface";
// eslint-disable-next-line no-unused-vars
import { Bike } from "../utils/Types";

export interface StartPageProps { }

export interface StartPageState {
	loadTrends: boolean;
	loadBestseller: boolean;
	loadNewest: boolean;
	bestsellerBikes: Bike[];
	trendBikes: Bike[];
	newestBikes: Bike[];
}

export default class StartPage extends Component<
	StartPageProps,
	StartPageState
	> {
	state = { loadTrends: true, loadBestseller: true, loadNewest: true, newestBikes: [], bestsellerBikes: [], trendBikes: [] };
	loadTrends = () => {
		BackendInterface.getData(
			"/bicycles/get?categories=Trends",
			(result) => {
				this.setState({ trendBikes: result, loadTrends: false });
			}
		);
	};

	loadBestseller = () => {
		BackendInterface.getData(
			"/bicycles/get?categories=Bestseller",
			(result) => {
				this.setState({ bestsellerBikes: result, loadBestseller: false });
			}
		);
	};

	loadNeueste = () => {
		BackendInterface.getData("/bicycles/get", (result: Bike[]) => {
			result = result.sort(
				(x: Bike, y: Bike) => y.time_stamp - x.time_stamp
			);
			this.setState({ newestBikes: result, loadNewest: false });
		});
	};

	componentDidMount() {
		this.loadBestseller();
		this.loadNeueste();
		this.loadTrends();
	}

	render() {

		return (
			<div className="start-page">
				<div className="start-page-slider">
					<StartpageSlider
						index={0}
						bikes={this.state.trendBikes}
						title="Trends"
						toAll="/home/search?categories=Trends"
					></StartpageSlider>
					<StartpageSlider
						index={1}
						bikes={this.state.bestsellerBikes}
						title="Bestseller"
						toAll="/home/search?categories=Bestseller"
					></StartpageSlider>
					<StartpageSlider
						index={2}
						bikes={this.state.newestBikes}
						title="Neueste"
						toAll="/home/search?categories=Neueste"
					></StartpageSlider>
				</div>
			</div>
		);
	}
}
