import React, { Component } from "react";
import "react-dates/initialize";
import RouterHistory from "../utils/RouterHistory";
import "./DetailPage.scss";
// eslint-disable-next-line no-unused-vars
import DetailpageSlider, { Img } from "../components/sliders/DetailpageSlider";
import BackendInterface from "../utils/BackendInterface";
// eslint-disable-next-line no-unused-vars
import { Bike, CartItem } from "../utils/Types";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import "moment/locale/de";
import { Spinner } from "@klumpp/tools";

export interface DetailPageProps { }

export interface Property {
	name: string;
	value: any;
}

export interface DetailPageState {
	loading: boolean;
	id: string;
	description: string;
	title: string;
	picSrc: string[];
	price: number;
	properties: Property[];
	inWishlist: boolean;
	categories: string[];
	extras: string[];
	srcs: Img[];
	goTo: number;
	dateFocus: null | "startDate" | "endDate";
	startDate: moment.Moment | null;
	endDate: moment.Moment | null;
	amount: number;
	days: any;
	isInShoppingCart: boolean;
	capacity: any;
}

export default class DetailPage extends Component<
	DetailPageProps,
	DetailPageState
	> {
	state = {
		loading: true,
		inWishlist: false,
		description: "",
		id: "",
		title: "",
		picSrc: [],
		price: 0,
		properties: [],
		categories: [],
		extras: [],
		srcs: [],
		goTo: 0,
		dateFocus: null,
		startDate: null,
		endDate: null,
		amount: 1,
		days: "",
		capacity: {},
		isInShoppingCart: false
	};

	loadWishlistToggle: boolean = false;


	createProperties = (bike: Bike) => {
		let props = [];
		props.push({ name: "Hersteller", value: bike.producer });
		props.push({ name: "Size", value: bike.wheel_size });
		props.push({ name: "Color", value: bike.color });

		return props;
	};

	loadBike = (id: string) => {
		BackendInterface.getData(`/bicycles/get/${id}`, (bike: Bike) => {
			this.setState({
				loading: false,
				description: bike.description,
				title: bike.name,
				id: bike.id,
				properties: this.createProperties(bike),
				categories: bike.categories ? bike.categories : [],
				extras: bike.extras ? bike.extras : [],
				price: bike.price,
				capacity: bike.capacity,
				srcs: bike.imgs.map((img) => {
					return { src: img, title: "" };
				}),
			});
		});
	};

	checkIfInWishlist = (id: string) => {
		window.auth.isAuthenticated().then((state) => {
			if (state) {
				BackendInterface.getData(`/wishlist/check/${id}`, (data) => {
					this.loadWishlistToggle = false;
					this.setState({ inWishlist: data.state });
				});
			} else {
				let wishlistBikes: any = window.localStorage.getItem("wishlistBikes");
				if (!wishlistBikes) {
					wishlistBikes = [];
				} else {
					wishlistBikes = JSON.parse(wishlistBikes);
				}
				let found = false;
				for (const i in wishlistBikes) {
					if (wishlistBikes[i] === id) {
						found = true;
					}
				}
				this.loadWishlistToggle = false;
				this.setState({ inWishlist: found });
			}
		});
	};

	toggleWishlist = () => {
		if (this.loadWishlistToggle === false) {
			this.loadWishlistToggle = true;
			window.auth.isAuthenticated().then((state) => {
				if (state) {
					if (!this.state.inWishlist) {
						BackendInterface.postData(
							"/wishlist/add",
							{ id: this.state.id },
							() => {
								this.checkIfInWishlist(this.state.id);
							}
						);
					} else {
						BackendInterface.deleteData(
							`/wishlist/remove/${this.state.id}`,
							{},
							() => {
								this.checkIfInWishlist(this.state.id);
							}
						);
					}
				} else {
					let wishlistBikes: any = window.localStorage.getItem("wishlistBikes");
					if (!wishlistBikes) {
						wishlistBikes = [];
					} else {
						wishlistBikes = JSON.parse(wishlistBikes);
					}
					if (this.state.inWishlist) {
						wishlistBikes = wishlistBikes.filter((bike: string) => bike !== this.state.id);
						window.localStorage.setItem("wishlistBikes", JSON.stringify(wishlistBikes));
					} else {
						wishlistBikes.push(this.state.id);
						window.localStorage.setItem("wishlistBikes", JSON.stringify(wishlistBikes));
					}
					this.checkIfInWishlist(this.state.id);
				}
			});
		}
	};

	componentDidMount() {
		this.setState({ goTo: RouterHistory.length - 1 });
		const id = window.location.pathname.split("/")[2];
		this.loadBike(id);
		this.checkIfInWishlist(id);
		moment.locale("de");

	}

	onPaymentTimeChange = (arg: { startDate: moment.Moment | null, endDate: moment.Moment | null }) => {
		const days = arg.startDate !== null && arg.endDate !== null ? ((arg.endDate as any) as moment.Moment).diff(arg.startDate, "days") + 1 : "";
		this.setState({ ...arg, days: days });
	}

	onChangeAmount = (event: any) => {
		const value = Number(event.target.value);
		if (!isNaN(value)) {
			this.setState({ amount: value, startDate: null, endDate: null });
		}
	}
	isDayBlocked = (day: any) => {


		const capacity = (this.state.capacity as any)[day.format("DD.MM.YYYY")];
		if (!capacity) {
			return true;
		}


		if (capacity - this.state.amount >= 0) {
			return false;
		}
		return true;
	}

	addToShoppingCart = () => {

		if (this.state.isInShoppingCart) {
			RouterHistory.push("/home/shoppingCart");
		} else {
			window.auth.isAuthenticated().then((state) => {
				if (this.state.amount !== 0 && this.state.id && this.state.days && this.state.startDate && this.state.endDate && this.state.price) {
					const cartItem: CartItem = {
						amount: this.state.amount, bicycle_id: this.state.id,
						days: Number(this.state.days), startDate: (this.state.startDate as any).format("x"),
						endDate: (this.state.endDate as any).format("x"),
						total: this.state.amount * Number(this.state.days) * this.state.price
					};
					if (state) {
						BackendInterface.postData("/cart/addItem", cartItem, () => {
							this.setState({ isInShoppingCart: true });
						});
					} else {
						let cart: any = window.localStorage.getItem("shoppingCart");
						if (!cart) {
							cart = [];
						} else {
							cart = JSON.parse(cart);
						}
						cart.push(cartItem);
						window.localStorage.setItem("shoppingCart", JSON.stringify(cart));
						this.setState({ isInShoppingCart: true });
					}
				}
			});

		}

	}

	render() {
		if (this.state.loading) {
			return <Spinner></Spinner>;
		}
		return (
			<div className="detail-page">
				<div className="detail-page-top">
					<button
						onClick={() => {
							RouterHistory.goBack();
						}}
						className="detail-page-top-back"
					>
						<i className="material-icons">arrow_back</i>
					</button>
					<div className="detail-page-top-title">
						{this.state.title}
					</div>
				</div>
				<div className="detail-page-side">
					<div className="detail-page-side-wrapper">
						<div className="detail-page-side-wishlist">
							<div className="detail-page-side-wishlist-star">
								<i className="material-icons">
									{this.state.inWishlist
										? "star"
										: "star_border"}
								</i>
							</div>
							<button
								onClick={this.toggleWishlist}
								className={
									this.state.inWishlist
										? "detail-page-side-wishlist-button active"
										: "detail-page-side-wishlist-button"
								}
							>
								{
									this.loadWishlistToggle ? (
										<Spinner></Spinner>
									) :
										this.state.inWishlist
											? "In Wunschliste"
											: "Zur Wunschliste hinzufügen"}
							</button>
						</div>
						<div className="detail-page-side-payment-select">
							<div className="payment-amount">
								<div className="payment-amount-label">Anzahl:</div>
								<div className="payment-amount-values">
									<input className="payment-amount-input" type="text" onChange={this.onChangeAmount} value={this.state.amount}></input>
								</div>
							</div>
							<div className="payment-time">
								<DateRangePicker
									endDateId="endDate"
									startDateId="startDate"
									orientation="vertical"
									small
									regular
									focusedInput={this.state.dateFocus}
									startDate={this.state.startDate}
									endDate={this.state.endDate}
									onDatesChange={this.onPaymentTimeChange}
									onFocusChange={(focus) => { this.setState({ dateFocus: focus }); }}
									displayFormat={"DD.MM.YYYY"}
									isDayBlocked={this.isDayBlocked}
									startDatePlaceholderText="Startdatum"
									endDatePlaceholderText="Enddatum"
									renderDayContents={(day) => {
										const capacity = (this.state.capacity as any)[day.format("DD.MM.YYYY")];
										return <div className="date-value-field">
											<div className="date-value">{day.format("DD")}</div>
											{capacity !== undefined && <div className="date-capacity">
												<div className="date-capacity-value">{capacity}</div>
												<i className="material-icons">directions_bike</i>
											</div>}
										</div>;
									}}
								/>
							</div>

						</div>
						<div className="detail-page-side-payment-information">
							<div className="payment-conclusion">
								<div className="payment-labels">
									<div className="payment-days-label">Tage:</div>
									<div className="payment-days">Preis pro Tag:</div>
								</div>
								<div className="payment-amount-values">
									<div className="payment-days">{this.state.days}</div>
									<div className="payment-price">{this.state.price} €</div>
								</div>
							</div>
							<div className="payment-price-infos">
								<div className="payment-price-total">
									<div className="payment-total-label">Total: </div>
									<div className="payment-total-price">{this.state.amount * Number(this.state.days) * this.state.price} €</div>
								</div>
								<div className="payment-add-to-shopping-cart">
									<button onClick={this.addToShoppingCart}>{this.state.isInShoppingCart ? "Zum Warenkorb." : "Zu Warenkorb hinzufügen."}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="detail-page-content">
					<div className="detail-page-content-details">
						<div className="detail-page-content-details-slider">
							<DetailpageSlider
								srcs={this.state.srcs}
							></DetailpageSlider>
						</div>
						<div className="detail-page-content-details-properties">
							<div className="detail-page-property-title">
								Informationen:
							</div>
							{this.state.properties.map(
								(prop: Property, index) => {
									return (
										<div
											className="detail-page-property"
											key={index}
										>
											<div className="detail-page-property-name">{`${prop.name}:`}</div>
											<div className="detail-page-property-value">
												{prop.value}
											</div>
										</div>
									);
								}
							)}

							<div className="detail-page-property-title">
								Kategorien:
							</div>
							<div className="detail-page-property-tags">
								{this.state.categories.map(
									(cat: string, index) => {
										return (
											<div
												key={index}
												className="detail-page-tag"
											>
												{cat}
											</div>
										);
									}
								)}
							</div>
							<div className="detail-page-property-title">
								Extras:
							</div>
							<div className="detail-page-property-tags">
								{this.state.extras.map(
									(extra: string, index) => {
										return (
											<div
												key={index}
												className="detail-page-tag"
											>
												{extra}
											</div>
										);
									}
								)}
							</div>
						</div>
					</div>
					<div className="detail-page-content-description">
						<div className="detail-page-content-description-title">
							Beschreibung
						</div>
						<div className="detail-page-content-description-text">
							{this.state.description}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
