import React, { Component } from "react";
import RouterHistory from "../../utils/RouterHistory";
import "./FilterPageItem.scss";

export interface FilterPageItemProps {
	id: string;
	src: string;
	wheelSize: string;
	color: string;
	extras: string[];
	producer: string;
	name: string;
}

export interface FilterPageItemState {}

export default class FilterPageItem extends Component<
	FilterPageItemProps,
	FilterPageItemState
> {
	render() {
		return (
			<div
				className="filter-page-item"
				onClick={() => RouterHistory.push(`/details/${this.props.id}`)}
			>
				<div className="filter-page-item-title">
					<div className="filter-page-item-title-name">
						{this.props.name}
					</div>
					<div className="filter-page-item-title-producer">
						{this.props.producer}
					</div>
				</div>
				<div className="filter-page-item-image">
					<img alt="Fahrrad Bild" src={this.props.src}></img>
				</div>
				<div className="filter-page-item-footer">
					<div className="filter-page-item-footer-wheel-color">
						<div className="filter-page-item-footer-wheel">
							<div className="filter-page-item-footer-wheel-title">
								Reifengröße
							</div>
							<div className="filter-page-item-footer-wheel-value">
								{this.props.wheelSize}
							</div>
						</div>
						<div className="filter-page-item-footer-color">
							<div className="filter-page-item-footer-color-title">
								Farbe
							</div>
							<div className="filter-page-item-footer-color-value">
								{this.props.color}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
