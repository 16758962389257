import React, { Component } from "react";
import { Spinner } from "@klumpp/tools";
import PayPal from "../../utils/PayPal";
import { Payment } from "../../utils/Types";
import BackendInterface from "../../utils/BackendInterface";
import RouterHistory from "../../utils/RouterHistory";
import moment from "moment";

interface PayPalRedirectProps { }

interface PayPalRedirectState { }

export default class PayPalRedirect extends Component<PayPalRedirectProps, PayPalRedirectState> {
    state = {};

    componentDidMount() {
        const paypal = new PayPal(
            process.env.REACT_APP_PAYPAL_CLIENT_ID,
            process.env.REACT_APP_PAYPAL_CLIENT_SECRET,
            process.env.REACT_APP_PAYPAL_RETURN_URL
        );

        if (sessionStorage.getItem("PAYPAL_LINKS") !== null) {
            window.auth.isAuthenticated().then((state) => {

                if (state) {
                    paypal.capturePayment(JSON.parse(sessionStorage.getItem("PAYPAL_LINKS") as string).authorize, email => {

                        sessionStorage.removeItem("PAYPAL_LINKS");
                        let payment = sessionStorage.getItem("paypal-data");

                        if (payment) {
                            const parsedPayment: Payment = JSON.parse(payment);
                            parsedPayment.date = Number(moment().format("x"));
                            BackendInterface.postData(`/payment/update/${parsedPayment.PaymentID}`, parsedPayment, () => {
                                RouterHistory.push(sessionStorage.getItem("paypal") as string);
                            });
                        }

                    });
                } else {
                    window.auth.loginWithRedirect();
                }
            });
        }
    }

    render() {
        return <Spinner></Spinner>;
    }
}