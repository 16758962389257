import req from "request-promise-native";
import { showNotification } from "./Notify";

export default class BackendInterface {
	static getAuthOptions = async (
		url: string,
		method: "GET" | "POST" | "DELETE" | "PATCH",
		body?: any
	) => {
		if (await window.auth.isAuthenticated()) {
			return {
				method: method,
				url: url,
				headers: {
					Authorization: `Bearer ${await window.auth.getAccessToken()}`,
				},
				body: body,
				json: true,
				timeout: 5000,
			};
		} else {
			return {
				method: method,
				url: url,
				body: body,
				json: true,
				timeout: 5000,
			};
		}
	};

	static request = async (
		method: "GET" | "POST" | "DELETE" | "PATCH",
		url: string,
		body: any,
		cb: (data: any) => void
	) => {
		let callback = (values: any, cb: any) => {
			if (!values.type) {
				cb([]);
			} else {
				showNotification(values, "bottom-right");
				cb(values.body);
			}
		};

		req(
			await BackendInterface.getAuthOptions(
				process.env.REACT_APP_API_URL + url,
				method,
				body
			)
		)
			.then((values) => {
				callback(values, cb);
			})
			.catch((error) => {
				// eslint-disable-next-line no-console
				console.log(error.message);
			});
	};

	static baseRequest = (
		method: "GET" | "POST" | "DELETE" | "PATCH",
		url: string,
		body: any,
		cb: (data: any) => void
	) => {
		BackendInterface.request(method, url, body, cb);
	};

	static getData = (url: string, cb: (data: any) => void) => {
		BackendInterface.baseRequest("GET", url, undefined, cb);
	};

	static postData = (url: string, body: any, cb: (data: any) => void) => {
		BackendInterface.baseRequest("POST", url, body, cb);
	};

	static patchData = (url: string, body: any, cb: (data: any) => void) => {
		BackendInterface.baseRequest("PATCH", url, body, cb);
	};

	static deleteData = (url: string, body: any, cb: (data: any) => void) => {
		BackendInterface.baseRequest("DELETE", url, body, cb);
	};
}
