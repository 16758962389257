import React, { Component } from "react";
import { Spinner } from "@klumpp/tools";
// eslint-disable-next-line no-unused-vars
import Auth from "./Auth";

interface IAuthCallbackProps {
	auth: Auth;
}
class Callback extends Component<IAuthCallbackProps> {
	componentDidMount = () => {
		// Handle authentication if expected values are in the URL.
		this.props.auth.handleAuthentication();
	};
	render() {
		return (
			<div className="auth-callback">
				<Spinner />
			</div>
		);
	}
}

export default Callback;
