import React, { Component } from "react";
import "./FilterPage.scss";
import FoldableFilterItem from "../components/filters/FoldableFilterItem";
import RouterHistory from "../utils/RouterHistory";
import Select from "../components/inputs/Select";
import Text from "../components/inputs/Text";
// eslint-disable-next-line no-unused-vars
import CheckboxField, { FilterItem } from "../components/inputs/CheckboxField";
// eslint-disable-next-line no-unused-vars
import { Bike } from "../utils/Types";
import BackendInterface from "../utils/BackendInterface";
import FilterPageItem from "../components/items/FilterPageItem";
import { Spinner } from "@klumpp/tools";

export interface FilterPageProps { }

export interface FilterPageState {
	bikeReload: boolean;
	loading: boolean;
	search: string;
	orderBy: any;
	producer: FilterItem[];
	categories: FilterItem[];
	colors: FilterItem[];
	wheelSizes: FilterItem[];
	extras: FilterItem[];
	items: Bike[];
}

export default class FilterPage extends Component<
	FilterPageProps,
	FilterPageState
	> {
	state = {
		bikeReload: true,
		loading: true,
		search: "",
		orderBy: "",
		producer: [],
		categories: [],
		colors: [],
		wheelSizes: [],
		extras: [],
		items: [],
	};
	wheelSizeRef = React.createRef<FoldableFilterItem>();
	colorRef = React.createRef<FoldableFilterItem>();
	extrasRef = React.createRef<FoldableFilterItem>();

	filters: any = {};

	getFilterOptions = async () => {
		let options: any = {};

		let promises: Promise<any>[] = [];

		promises.push(
			new Promise<any>((resolve) => {
				BackendInterface.getData("/extras/get", (extras) => {
					options.extras = extras.map((extra: any) => {
						return { name: extra, value: false };
					});
					resolve(true);
				});
			})
		);

		promises.push(
			new Promise<any>((resolve) => {
				BackendInterface.getData("/producers/get", (producers) => {
					options.producer = producers.map((prod: any) => {
						return { name: prod, value: false };
					});
					resolve(true);
				});
			})
		);

		promises.push(
			new Promise<any>((resolve) => {
				BackendInterface.getData("/categories/get", (categories) => {
					options.categories = categories.map((category: any) => {
						return { name: category, value: false };
					});
					resolve(true);
				});
			})
		);

		promises.push(
			new Promise<any>((resolve) => {
				BackendInterface.getData("/colors/get", (colors) => {
					options.colors = colors.map((color: any) => {
						return { name: color, value: false };
					});
					resolve(true);
				});
			})
		);

		promises.push(
			new Promise<any>((resolve) => {
				BackendInterface.getData("/wheels/get", (sizes) => {
					options.wheelSizes = sizes.map((size: any) => {
						return { name: size, value: false };
					});
					resolve(true);
				});
			})
		);

		await Promise.all(promises);

		return options;
	};

	loadBikes = () => {
		this.setState({ bikeReload: true }, () => {
			BackendInterface.getData(
				`/bicycles/get${window.location.search}`,
				(bicycles) => {
					this.setState({ items: bicycles, loading: false, bikeReload: false });
				}
			);
		});

	};

	filterBikes = () => { };

	async componentDidMount() {
		let options: any = await this.getFilterOptions();

		let filterQuery = window.location.search.substring(1).split("&");
		let filters: any = {};
		for (let filter of filterQuery) {
			let valueLabel = filter.split("=");
			if (valueLabel.length === 2) {
				if (valueLabel[0] === "search" || valueLabel[0] === "orderBy") {
					filters[valueLabel[0]] = valueLabel[1]
						.split(",")
						.filter((item) => item !== "")
						.map((item) => {
							return decodeURIComponent(item);
						})[0];
				} else {
					filters[valueLabel[0]] = valueLabel[1]
						.split(",")
						.filter((item) => item !== "")
						.map((item) => {
							return {
								name: decodeURIComponent(item),
								value: true,
							};
						});
				}
			}
		}

		// Filter anwenden auf State

		if (!filters["orderBy"]) {
			filters["orderBy"] = "Neueste";
		}

		for (let filter in filters) {
			if (filter === "extras") {
				this.extrasRef.current?.open();
			}

			if (filter === "colors") {
				this.colorRef.current?.open();
			}

			if (filter === "wheelSizes") {
				this.wheelSizeRef.current?.open();
			}

			if (filter === "orderBy") {
				this.onChangeFilter("orderBy", filters[filter]);
			} else if (filter === "search") {
				this.onChangeFilter("search", filters[filter]);
			} else {
				for (let item of filters[filter]) {
					options[filter] = options[filter].map((it: any) => {
						if (it.name === item.name) {
							return item;
						} else {
							return it;
						}
					});
					this.onChangeFilter(filter, options[filter]);
				}
			}
		}
		this.setState(options, () => {
			this.loadBikes();
		});
	}

	searchTimer?: any = undefined;
	onChangeFilter = (name: string, values: any) => {
		if (Array.isArray(values)) {
			this.filters[name] = {};
			values.forEach(
				(item) => (this.filters[name][item.name] = item.value)
			);
		} else {
			this.filters[name] = values;
		}

		let url = "/home/search?";
		let filterStrings: string[] = [];

		for (let filter in this.filters) {
			let filterString = `${filter}=`;
			let items: string[] = [];

			if (filter === "search") {
				this.setState({ search: this.filters[filter] });
				items.push(this.filters[filter]);
			} else if (filter === "orderBy") {
				this.setState({ orderBy: this.filters[filter] });
				items.push(this.filters[filter]);
			} else {
				for (let item in this.filters[filter]) {
					if (this.filters[filter][item]) {
						items.push(item);
					}
				}
			}
			if (items.length !== 0) {
				filterString += items.join(",");
				filterStrings.push(filterString);
			}
		}

		url += filterStrings.join("&");

		RouterHistory.replace(url);
		if (name === "search") {
			if (this.searchTimer) {
				clearTimeout(this.searchTimer);
				this.searchTimer = undefined;
			}
			this.searchTimer = setTimeout(() => {
				this.loadBikes();
			}, 400);
		} else {
			this.loadBikes();
		}
	};

	render() {

		if (this.state.loading) {
			return <Spinner></Spinner>;
		}

		const orderByItems = [
			"Neueste",
			"Preis aufsteigend",
			"Preis absteigend",
			"Name",
		];
		return (
			<div className="filter-page">
				<div className="filter-topbar">
					<div className="filter-page-search">
						<Text
							value={this.state.search}
							onChange={(value) => {
								this.onChangeFilter("search", value);
							}}
						></Text>
					</div>
					<div className="filter-page-orderby">
						<Select
							items={orderByItems}
							onChange={(value) =>
								this.onChangeFilter("orderBy", value)
							}
							value={this.state.orderBy}
						></Select>
					</div>
				</div>
				<div className="filter-sidebar">
					<div className="filter-sidebar-wrapper">
						<div className="filter-sidebar-title">Filter</div>
						<div className="filter-sidebar-filter">
							<FoldableFilterItem open title="Hersteller">
								<CheckboxField
									items={this.state.producer}
									onChange={(values) =>
										this.onChangeFilter("producer", values)
									}
								/>
							</FoldableFilterItem>
							<FoldableFilterItem open title="Kategorie">
								<CheckboxField
									onChange={(values) =>
										this.onChangeFilter(
											"categories",
											values
										)
									}
									items={this.state.categories}
								></CheckboxField>
							</FoldableFilterItem>
							<FoldableFilterItem
								ref={this.colorRef}
								title="Farbe"
							>
								<CheckboxField
									onChange={(values) =>
										this.onChangeFilter("colors", values)
									}
									items={this.state.colors}
								></CheckboxField>
							</FoldableFilterItem>
							<FoldableFilterItem
								ref={this.wheelSizeRef}
								title="Reifengröße"
							>
								<CheckboxField
									onChange={(values) =>
										this.onChangeFilter(
											"wheelSizes",
											values
										)
									}
									items={this.state.wheelSizes}
								></CheckboxField>
							</FoldableFilterItem>
							<FoldableFilterItem
								title="Extras"
								ref={this.extrasRef}
							>
								<CheckboxField
									onChange={(values) =>
										this.onChangeFilter("extras", values)
									}
									items={this.state.extras}
								></CheckboxField>
							</FoldableFilterItem>
						</div>
					</div>
				</div>
				<div className="filter-content">
					{this.state.bikeReload ? <Spinner></Spinner> : this.state.items.map((item: Bike, index) => (
						<FilterPageItem
							key={index}
							name={item.name}
							color={item.color}
							extras={item.extras}
							producer={item.producer}
							id={item.id}
							src={item.imgs.length === 0 ? "" : item.imgs[0]}
							wheelSize={item.wheel_size}
						/>
					))}
				</div>
			</div>
		);
	}
}
