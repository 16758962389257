import { Auth0Client } from "@auth0/auth0-spa-js";

export default class Auth {
	history: any;
	auth0: Auth0Client;
	redirectUri: string;
	audience: string;
	logoutUri: string;

	constructor(
		history: any,
		domain: string,
		clientId: string,
		redirectUri: string,
		audience: string,
		logoutUri: string
	) {
		this.history = history;
		this.redirectUri = redirectUri;
		this.audience = audience;
		this.logoutUri = logoutUri;

		this.auth0 = new Auth0Client({
			domain: domain,
			client_id: clientId,
			redirect_uri: this.redirectUri,
			audience: this.audience,
		});
	}

	isAuthenticated = () => {
		return this.auth0.isAuthenticated();
	};

	loginWithRedirect = (redirectUri?: string) => {
		this.auth0.loginWithRedirect({
			appState: redirectUri ? redirectUri : window.location.pathname,
		});
	};

	logout = () => {
		this.auth0.logout({ returnTo: this.logoutUri });
	};

	getUser = () => {
		return this.auth0.getUser();
	};

	handleAuthentication = async () => {
		this.auth0
			.handleRedirectCallback()
			.then((value: any) => {
				this.history.replace(value.appState);
			})
			.catch(() => {
				this.loginWithRedirect();
			});
	};

	getAccessToken = async () => {
		if (!(await this.auth0.isAuthenticated())) {
			this.loginWithRedirect();
		}

		return this.auth0.getTokenSilently().catch(() => {
			this.loginWithRedirect();
		});
	};
}
