import React, { Component } from "react";
import WishlistpageSlider from "../components/sliders/WishlistpageSlider";
// eslint-disable-next-line no-unused-vars
import { Bike } from "../utils/Types";
import BackendInterface from "../utils/BackendInterface";

export interface WishlistPageProps { }

export interface WishlistPageState { }

export default class WishlistPage extends Component<
	WishlistPageProps,
	WishlistPageState
	> {

	state = {
		wishlistBikes: []
	}

	wishlistBikes = (ids: string[]) => {
		let wishlistBikes: Bike[] = [];
		ids.forEach(id => {
			BackendInterface.getData(
				`/bicycles/get/${id}`,
				(result) => {
					wishlistBikes.push(result);
					if (ids.length === wishlistBikes.length) {
						this.setState({ wishlistBikes });
					}
				}
			);
		});
	}

	isUser = () => {
		window.auth.isAuthenticated().then((state) => {
			if (state) {
				let wishlistBikes: any = window.localStorage.getItem("wishlistBikes");
				if (!wishlistBikes) {
					wishlistBikes = [];
				} else {
					wishlistBikes = JSON.parse(wishlistBikes);
				}

				if (wishlistBikes.length === 0) {
					BackendInterface.getData(
						"/wishlist/get",
						(result) => {
							let bikeIds: string[] = [];
							result.forEach((el: { UserID: string, BicycleID: string; }) => {
								bikeIds.push(el.BicycleID);
							});
							this.wishlistBikes(bikeIds);
						}
					);
				}

				
				let count = 0;
				for (const id of wishlistBikes) {
					BackendInterface.postData(
						"/wishlist/add",
						{ id: id },
						// eslint-disable-next-line
						() => {
							count++;
							if (wishlistBikes.length === count) {
								window.localStorage.removeItem("wishlistBikes");
								BackendInterface.getData(
									"/wishlist/get",
									(result) => {
										let bikeIds: string[] = [];
										result.forEach((el: { UserID: string, BicycleID: string; }) => {
											bikeIds.push(el.BicycleID);
										});
										this.wishlistBikes(bikeIds);
									}
								);
							}
						});
				}

			} else {
				window.auth.loginWithRedirect();
			}
		});
	}

	componentDidMount() {
		this.isUser();
	}

	render() {
		return (
			<WishlistpageSlider
				index={0} title={"Dein Merkzettel"} bikes={this.state.wishlistBikes}
			></WishlistpageSlider>
		);
	}
}
