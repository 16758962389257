import React, { Component } from "react";
import "./CheckboxField.scss";
import Checkbox from "./Checkbox";

export interface FilterItem{
	name: string;
    value: boolean;
}

export interface CheckboxFieldProps{
    items: FilterItem[];
    onChange: (values: any) => void;
}

export interface CheckboxFieldState{
}

export default class CheckboxField extends Component<CheckboxFieldProps, CheckboxFieldState>{

    onChangedValue = (name: string, value: boolean) => {
    	let values = this.props.items;
    	values[values.findIndex(item => item.name === name)].value = value;
    	this.props.onChange(values);
    }

    render(){
    	return <div className="checkbox-field">{
    		this.props.items.map((item, number) => <Checkbox value={this.props.items.find(it => item === it)?.value?true:false} key={number} label={item.name} onChange={(value) => this.onChangedValue(item.name, value)}></Checkbox>)
    	}</div>;
    }
}